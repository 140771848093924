

import gsap from "~/mixins/gsap";
export default {
    props: ['blok'],
    mixins : [gsap],
    computed : {
        wW(){
            return this.$store.state.scroll.wW;
        }
    },
    data(){
        return {
            currentIndex: 0,
            changing: false,
            seconds: 2000,
            isAnimating : false,
            animateHolder : null
        }
    },
    mounted() {
        this.autoAnimate();
    },
    beforeDestroy() {
        if(this.animateHolder) clearTimeout(this.animateHolder);
    },
    methods: {
        autoAnimate(){
            this.animateHolder = setTimeout(() => {
                this.changeSlide(1);                
            }, this.seconds * 1000);
        },
        checkCurrent(e){
            if(this.animateHolder) clearTimeout(this.animateHolder);
            if(this.$refs && this.$refs.wrapper && this.$refs.wrapper.scrollLeft){
                this.autoAnimate();
                this.currentIndex = this.$refs.wrapper.scrollLeft/this.wW;
            }
        },
        changeSlide(dir){
            if(!this.isAnimating && this.$refs && this.$refs.wrapper){
                var calcY = this.$refs.wrapper.scrollLeft + dir * this.wW;
                calcY = calcY > this.$refs.wrapper.scrollWidth - this.wW ? 0 : calcY;
                calcY = calcY < 0  ? this.$refs.wrapper.scrollWidth - this.wW : calcY;
                this.gsap.to(this.$refs.wrapper,{
                    scrollTo : {x:calcY,y:0},
                    duration: 1 ,
                    onComplete : () => { 
                        this.isAnimating = false;
                    }
                });
                this.isAnimating = true;
            }
        }
    },
}
